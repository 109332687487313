<template>
  <div class="text-center mx-auto com-home-CooperativePartner" >
    <h1>合作伙伴</h1>
    <a-carousel arrows :dots="false" autoplay interval={3000} v-if="getBrowser == 'pc'">
      <div
        slot="prevArrow"
        class="custom-slick-arrow"
        style="left: -30px; zindex: 1"
      >
        <a-icon type="left" />
      </div>
      <div slot="nextArrow" class="custom-slick-arrow" style="right: -30px">
        <a-icon type="right" /> 
      </div>

      <div v-for="(item, index) in source.list" :key="index">
        <div class="cp-item">
          <div
            class="cp-bg cp-bg-5"
            v-for="(item2, subIndex) in item.child"
            :key="subIndex"
            :style="`background-image:url(${item2.picBack});`"
          >
            <img :src="item2.picLogo" />
            <h3>{{ item2.descInfo }}</h3>
          </div>
        </div>
      </div>
    </a-carousel>

    <div v-if="getBrowser == 'phone'" class="swipperBox">
           <van-swipe type="card"   :loop="true" :autoplay="3000">
            <van-swipe-item   v-for="(item, index) in source.listPhone" :key="index" style="background:white;border-radius:8px;">
              <div  
                :style="`background-image:url(${item.picBack}); background-size:100% 60%;background-repeat:no-repeat;height:100%`">
                    <img :src="item.picLogo" />
                    <h3>{{ item.descInfo }}</h3>
              </div>
             
            </van-swipe-item>   
        </van-swipe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      source: {
        list: [],
        listPhone: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const res =
        await this.api.service.fpc_oapi_customerPic_partnersCustomerPic({}, {});
      await this.api.toast(res, 0);
      let list = [];
      this.source.listPhone =  res.data;
      res.data.forEach(({ brandName, descInfo, picLogo, picBack }, i) => {
        const index = i % 4;
        if (index === 0) {
          list.push({ child: [] });
        }
        list[list.length - 1].child.push({
          brandName,
          descInfo,
          picLogo,
          picBack,
        });
      });
      this.source.list = list;
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .com-home-CooperativePartner {
   background:#F7F8FA;;
   width: 100%;
  h1 {
    padding-bottom: 20px;
    color: #041334;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:25px;
    
  }
  .swipperBox{
    width:100%;
    border-radius:8px;
    .van-swipe{
      width:100%;
      img{
        width:5rem;
        height:5rem;
        margin-top:30%;
        border-radius:8px;
      }
      h3 {
          color: black;
          font-family: "PingFang SC";
          font-size: 16px;
          font-weight: 700;
          margin-bottom:2rem;
          margin-top:1rem;
        }
     }
  }

 
}
}
@media screen and (min-width: 1024px) {
  .com-home-CooperativePartner {
    padding-top: 80px;
    padding-bottom: 160px;
    width: 1200px;
  h1 {
    padding-bottom: 35px;
    font-size: 28px;
    color: #202121;
    font-weight: 500;
    font-family: PingFangSC-Medium;
  }

  .ant-carousel .custom-slick-arrow i {
    color: #646666;
  }

  .cp-item {
    display: flex;
    .cp-bg {
      transition: 0.5s;
      height: 300px;
      width: 300px;
      border: 15px solid #f7f8fa;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left center;
      img {
        transition: 0.5s;
        width: 80px;
        margin-top: 100px;
      }
      h3 {
        transition: 0.5s;
        color: white;
        font-size: 20px;
        margin-top: 20px;
        opacity: 0;
      }
      &:hover {
        width: 504px !important;
        h3 {
          opacity: 1;
        }
      }
    }
    &:hover {
      .cp-bg {
        width: 277px;
      }
    }
  }
}
}

</style>
